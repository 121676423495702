<template>
    <div class="MyArea">
        <van-field v-model="inputValue" clickable readonly v-bind="$attrs" @click-input="showArea = true">
            <van-icon v-if="!$attrs.disabled && $attrs.clearable && inputValue" slot="right-icon" name="clear" @click="onClear" />
        </van-field>
        <van-popup v-model="showArea" position="bottom" round>
            <van-area ref="area"
                      :area-list="areaList"
                      :columns-num="2"
                      :value="value"
                      title="选择省市区"
                      @cancel="showArea = false"
                      @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "MyArea",
        inheritAttrs: false,
        props: {
            value: [String, Number],
            areaList: Object
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                showArea: false,
                inputValue: ''
            }
        },
        watch: {
            value: {
                handler: 'setDefault'
            }
        },
        mounted() {
            this.setDefault();
        },
        methods: {
            onClear() {
                this.inputValue = '';
                this.$emit('input', '');
            },
            // 回显默认值
            setDefault() {
                if (this.value) {
                    // 根据编号获取对应地区文字
                    this.inputValue = this.$getArea(this.value, this.areaList);
                } else {
                    this.inputValue = '';
                }
            },
            // 确认选择
            onConfirm(value) {
                // let [, item] = value;
                this.inputValue = value.map(v => v.name).join(' / ');
                this.$emit('input', value.map(v => v.code));
                this.showArea = false;
            }
        }
    }
</script>

<style scoped>

</style>
